import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import { BaseStore, Store, Field } from '@zento/lib/stores/BaseStore';

import { subscribeToNewsletter, SubscribeToNewsletterMutation, unsubscribeToNewsletter } from './operations.graphql';

@Store
export class NewsletterStore extends BaseStore {
  /**
   * Newsletter types
   */
  @Field
  private newsletterStatus: SubscribeToNewsletterMutation['subscribeEmailToNewsletter']['status'];

  @Field
  private errorMessageNewsletter: string;

  @Field
  private loadingData: boolean;

  @Field
  private newsletterCheckout: boolean;

  @Field
  private newsletterDrawer: boolean;

  @Field
  private newsletterEmal: string;

  /**
   * Fetch Newsletter
   */
  public async subscribeEmailToNewsletter(email: string) {
    this.loadingData = true;

    return this.mutate(async () => {
      try {
        // Add current store code to header
        this.dataSources.graphQl.customHeaders = {
          ...this.dataSources.graphQl.customHeaders,
          Store: currentStoreView().storeCode || 'default',
        };

        const resp = await this.dataSources.graphQl.queue({
          ...subscribeToNewsletter,
          params: { email },
        });

        if (resp.subscribeEmailToNewsletter) {
          this.newsletterStatus = resp.subscribeEmailToNewsletter.status;
          this.errorMessageNewsletter = '';
        }
      } catch (e) {
        this.newsletterStatus = '';
        this.errorMessageNewsletter = e.message;
        console.error('Newsletter Store (subscribeEmailToNewsletter): ', e);
      }

      this.loadingData = false;
    });
  }

  public async unsubscribeEmailToNewsletter(customerId: number) {
    return this.mutate(async () => {
      try {
        // Add current store code to header
        this.dataSources.graphQl.customHeaders = {
          ...this.dataSources.graphQl.customHeaders,
          Store: currentStoreView().storeCode || 'default',
        };

        const resp = await this.dataSources.graphQl.queue({
          ...unsubscribeToNewsletter,
          params: { customerId },
        });

        if (resp.unsubscribeEmailFromNewsletter) {
          this.newsletterStatus = resp.unsubscribeEmailFromNewsletter.status;
          this.errorMessageNewsletter = '';
        }
      } catch (e) {
        this.newsletterStatus = '';
        this.errorMessageNewsletter = e.message;
        console.error('Newsletter Store (unsubscribeEmailToNewsletter): ', e);
      }
    });
  }

  public get customerNewsletterStatus() {
    return this.newsletterStatus || null;
  }

  public get errorMessage() {
    return this.errorMessageNewsletter || '';
  }

  public get loading() {
    return this.loadingData;
  }

  public get newsletterCheckoutData() {
    return this.newsletterCheckout;
  }

  public set newsletterCheckoutData(value: boolean) {
    this.newsletterCheckout = value;
  }

  /**
   *  Get Newsletter drawer getter
   */
  public get drawerState() {
    return this.newsletterDrawer ?? null;
  }

  /**
   *  Set Newsletter drawer getter
   */
  public set drawerState(state: boolean) {
    this.newsletterDrawer = state;
  }

  /**
   *  Get Newsletter subscribed email
   */
  public get emailValue() {
    return this.newsletterEmal;
  }

  /**
   *  Set Newsletter subscribed email
   */
  public set emailValue(email: string) {
    this.newsletterEmal = email;
  }
}
